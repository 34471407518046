.cta-button {
  margin-bottom: 1rem;
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1f2f5f;
  font-weight: bold;
}

.cta-button:hover {
  margin-bottom: 1rem;
  background-color: #475c95;
  border-color: #374c85;
  color: white;
  font-weight: bold;
}

.cta-button-nav {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #1f2f5f;
  font-weight: bold;
}

.cta-button-nav:hover {
  background-color: #475c95;
  border-color: #374c85;
  color: white;
  font-weight: bold;
}