.user-clip-wrapper {
    max-width: 100px;
    text-align: center;
    display: inline-block;
}

.user-clip-image {
    margin-bottom: 10px;
}

.user-clip-name {
    color: #454545;
    margin-bottom: 4px;
}

.user-clip-info {
    text-align: left;
    color: #454545;
    min-width: 120px;
}

.user-clip-wrapper pill {
    padding: 3px 10px;
    border-radius: 3px;
    border: 1px solid #f5f5f5;
    color: #efefef;
    font-size: .8em;
    background-color: #232323;
}

.user-clip-wrapper .stat {
    font-size: .8em;
    color: #454545;
    margin-right: 8px;
    display: inline;
}

.user-clip-wrapper .icon {
    width: 12px;
    margin: 0px 0px 2px 0px;
}