.home-cta {
  padding: 150px 40px;
  height: 600px;
  /* background: rgb(212,133,82);
  background: linear-gradient(13deg, rgba(212,133,82,1) 0%, rgba(76,255,155,1) 100%); */
  background: rgb(29,107,121);
  background: linear-gradient(13deg, rgba(29,107,121,1) 0%, rgba(0,255,188,1) 100%);
  margin-top: -4px;
  color: #ffffff;
  font-size: 1.2em;
}

.home-container h1 {
  background: #000000;
  padding: 10px;
  color: #ffffff;
  display: inline;
  font-weight: bold;
  font-size: 4em;
}

.home-container h2 {
  background: #000000;
  padding: 10px;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  font-size: 2.5em;
}

.home-container section {
  padding: 125px;
  font-size: 1.2em;
}

.home-container .alt-section {
  background-color: #223262;
}

.home-container .plugin-img {
  max-width: 250px;
}

.home-container .community-img {
  max-width: 100px;
  background-color: #000000;
  padding: 20px;
}

@media only screen and (max-width: 600px) {
  .home-cta {
    height: 700px;
    font-size: 1.1em;
  }

  .home-container section {
    padding: 40px;
  }

  .home-container h1 {
    font-size: 2.5em;
  }

  .home-container h2 {
    font-size: 2em;
  }

  .home-container section {
    padding: 30px;
    font-size: 1.2em;
  }
}