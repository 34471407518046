.preset-cell-name {
  font-weight: bold;
}

.preset-cell-details {
  font-size: 12px;
}

.preset-cell-actions {
  text-align: right;
  vertical-align: middle;
}

.pill {
  display: inline;
  padding: 6px 6px 6px 0px;
  color: #787878;
  margin: 4px 4px 4px 0px;
  font-size: 1.1em;
  font-weight: bold;
}

.icon {
  width: 12px;
  margin-right: 2px;
}