.account-container {

}

.account-header {
  margin-top: -4px;
  padding: 50px;
  width: 100%;
  height: 300px;
  /* background: rgb(212,133,82);
  background: linear-gradient(13deg, rgba(212,133,82,1) 0%, rgba(76,255,155,1) 100%); */
  background: rgb(29,107,121);
  background: linear-gradient(13deg, rgba(29,107,121,1) 0%, rgba(0,255,188,1) 100%);
  box-shadow: 0.0em 0.0em 2.0em rgba(0,0,0,0.9);
  font-size: 50px;
  text-align: center;
}

.account-container section {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dedede;
}
