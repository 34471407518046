.account-profile-pic {
  height: 150px;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,.4);
  border: 4px solid rgba(0,0,0,0.0);
  object-fit: cover;
}

.sm-pic {
  height: 100px;
  width: 100px;
  border: 3px solid rgba(0,0,0,0.0);
}

.lg-pic {
  height: 225px;
  width: 225px;
}

.account-profile-placeholder {
  height: 150px;
  width: 150px;
  padding: 15px;
  text-align: center;
  background-color: #ffffff;
  color: #3f3f3f;
  font-size: 75px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,.4);
  border: 4px solid rgba(0,0,0,0.0);
  border-radius: 50%;
}

.lg-placeholder {
  height: 225px;
  width: 225px;
  font-size: 115px;
}

.sm-placeholder {
  height: 75px;
  width: 75px;
  padding: 8px;
  background: rgb(29,107,121);
  background: linear-gradient(13deg, rgba(29,107,121,1) 0%, rgba(0,255,188,1) 100%);
  border: 0px;
  color: #ffffff;
  font-size: 40px;
}

@media only screen and (max-width: 767px) {
  .sm-pic {
    height: 75px;
    width: 75px;
    border: 3px solid rgba(0,0,0,0.0);
  }
  
  .lg-pic {
    height: 100px;
    width: 100px;
  }

  .lg-placeholder {
    height: 100px;
    width: 100px;
    margin-left: 0px;
    margin-right: 0px;
    font-size: 60px;
    padding: 0px;
  }
}
