html, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  background-color: #192a56;
  color: white;
}

h1 {
  font-family: 'Expletus Sans', sans-serif;
}

h2 {
  font-family: 'Expletus Sans', sans-serif;
}

h3 {
  font-family: 'Expletus Sans', sans-serif;
}

.root {
  height: 100%;
}

.navbar {
  background-color: #273c75;
}

.navbar-brand {
  font-family: 'Expletus Sans', sans-serif;
  font-size: 1.5em;
}

.pagination {
  margin-top: 10px;
}

.page-item.disabled .page-link {
  background-color: #2f3f6f;
  border-color: #374c85;
  color: #6f7faf;
}

.page-item .page-link {
  background-color: #1f2f5f;
  border-color: #374c85;
  color: white;
}

.form-control:disabled {
  background-color: #dedede;
  border-color: #ababab;
  color: #666666;
}

.form-control {
  background-color: #ffffff;
  border-color: #dedede;
  color: #000000;
}

.input-group-text {
  background-color: #ffffff;
  border-color: #dedede;
  color: #000000;
}

.table {
  background-color: #1f2f5f;
  border-color: #374c85;
  color: white;
}

.table tbody tr:hover {
  background-color: #2f3f6f;
  border-color: #374c85;
  color: white;
}

.popover-header {
  color: #000000;
}

.padding-bottom {
  padding-bottom: 20px;
}

.margin-bottom {
  margin-bottom: 20px;
}

.list-group-item .col {
  padding: 0px;
}

.list-group-item .col-md-auto {
  padding: 0px;
}

.list-group-item .btn {
  margin-bottom: 2px;
}

.text-shadow {
  text-shadow: 0.05em 0.05em 0.2em rgba(0,0,0,0.4);
}

.inline-block {
  display: inline-block;
}

.body-wrapper {
  background-color: #ffffff;
  color: #000000;
  padding: 20px;
  min-height: 800px;
  width: 100%;
  border-top: 1px solid #aaaaaa;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0.0em 0.6em 0.5em rgba(0,0,0,0.3);
}

.error {
  padding: 10px;
  border: 1px solid #d63031;
  border-radius: 5px;
  background-color: #a60001;
  margin: 5px 0px;
  color: white;
  font-weight: bold;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.invalid-feedback {
  color: #000000;
}

@media only screen and (max-width: 767px) {
  .container {
      padding-left: 0px;
      padding-right: 0px;
  }
}

@import './components/shared/styles/ButtonStyles.css'