.preset-body {
    background: #ffffff;
    color: #000000;
    min-height: 500px;
    padding: 20px;
}

.preset-detail-wrapper {
    margin-bottom: 20px;
}

.preset-detail-title {
    font-weight: bold;
    font-size: 1.5em;
    padding-bottom: 5px;
    color: #454545
}

.preset-notes {
    background: #f4f4f4;
    font-size: .9em;
    border-radius: 5px;
    border: 1px solid #f0f0f0;
    padding: 15px;
    max-width: 600px;
    overflow: scroll;
}

.preset-filename {
    background: #f4f4f4;
    color: #888888;
    font-size: .9em;
    border-radius: 5px;
    border: 1px solid #f0f0f0;
    padding: 10px 15px;
    max-width: 600px;
    overflow: scroll;
}

.button-bar {
    padding: 0px 20px 20px 20px;
}

.button-bar .icon {
    width: 12px;
}

.button-bar .btn {
    margin: 0px 4px;
}

.button-bar .pill {
    display: inline;
    padding: 6px;
    color: #787878;
    margin: 4px;
    font-size: .8em;
}

.divider {
    margin: 0px;
    height: 1px;
    border: none;
    background-color: #efefef;
}

.divider-row {
    margin: 0px 0px 10px 0px;
    height: 1px;
}

.preset-body .user-name {
    display: inline;
    font-weight: bold;
    font-size: 14px;
}

.preset-body .date {
    color: #787878;
    padding-left: 10px;
    font-size: 14px;
}