.nav-link {
  
}

.profile-pic {
  height: 30px;
  width: 30px;
  border: 1px solid #ffffff;
  object-fit: cover;
}

.no-profile-pic {
  padding-top: 3px;
  text-align: center;
  background-color: #ffffff;
  color: #000000;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
}
