#signupform-container {
  margin-left: auto;
  margin-right: auto;
  border: 3px solid #ffffff;
  border-radius: 20px;
  padding: 100px 20px 20px 20px;
  width: 50%;
  max-width: 500px;
  /* background: rgb(212,133,82);
  background: linear-gradient(13deg, rgba(212,133,82,1) 0%, rgba(76,255,155,1) 100%); */
  background: rgb(29,107,121);
  background: linear-gradient(13deg, rgba(29,107,121,1) 0%, rgba(0,255,188,1) 100%);
  position: relative;
  font-size: 20px;
}

.signup-photo {
  position: absolute;
  top: -60px;
  left: 150px;
  height: 150px;
  width: 150px;
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,.4);
  border: 4px solid rgba(0,0,0,0.0);
}

.no-account {
  padding-top: 20px;
  font-size: 14px;
}

#signupform-container a {
  color: #ffffff;
  font-weight: bold;
}

@media (max-width: 769px) {
  #signupform-container {
    width: 100%;
  }
}