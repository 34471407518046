.preset-header {
    margin-top: -4px;
    padding: 20px;
    height: 300px;
    /* background: rgb(212,133,82);
    background: linear-gradient(13deg, rgba(212,133,82,1) 0%, rgba(76,255,155,1) 100%); */
    background: rgb(29,107,121);
    background: linear-gradient(13deg, rgba(29,107,121,1) 0%, rgba(0,255,188,1) 100%);
    box-shadow: 0.0em 0.0em 2.0em rgba(0,0,0,0.9);
    /* font-size: 50px; */
}

.preset-header h1 {
    background: #000000;
    padding: 10px;
    color: #ffffff;
    display: inline-block;
}

.preset-header h2 {
    padding: 10px;
    color: #ffffff;
}

.preset-header h3 {
    padding: 10px;
    color: #ffffff;
}

@media only screen and (max-width: 767px) {
    .preset-header h1 {
        font-size: 1.5em;
    }

    .preset-header h2 {
        font-size: 1.3em;
    }

    .preset-header h3 {
        font-size: 1.1em;
    }
}
