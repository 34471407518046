.plugin-image {
    background: #000000;
    width: 250px;
    height: 250px;
    padding: 20px;
    object-fit: contain;
    visibility: visible;
}

@media only screen and (max-width: 767px) {
    .plugin-image {
        width: 0px;
        visibility: hidden;
    }
}