#app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 60px;
}

#content-container {
  flex: 1 0 auto;
}
