#footer {
  flex-shrink: 0;
  background-color: #000000;
  height: 200px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 40px;
  font-size: 14px;
  color: #cdcdcd;
}

#footer .right-align {
  text-align: right;
}

#footer a {
  font-weight: bold;
  color: #ffffff
}

#footer a:hover {
  font-weight: bold;
  color: #ffffff
}

#footer a:visited {
  font-weight: bold;
  color: #ffffff
}